import React, {Component} from "react";
import {Button, Divider, Select, Spin, Form, Checkbox, Modal} from "antd";
import Cookies from "universal-cookie";
import {groupServices} from "../../services/amartaVip/GroupService";
import {trackAgentService} from "../../services/amartaVip/TrackAgentService";
import {userServices} from "../../services/amartaVip/UserService";

class UpdateAdditionalData extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            processingFetch: true,
            dataListSpv: [],
            dataListTeam: [],
            dataTrackingAmarta: [],
            dataTrackingChery: [],
            loading: false,

            spvSelected: "",
            teamSelected: "",
            amarta_TRACKING_LEAD_AGENT: false,
            amarta_TRACKING_ORDER_AGENT: false,
            chery_TRACKING_LEAD_AGENT: false,
            chery_TRACKING_TEST_DRIVE_AGENT: false,
        }
        this.state = {...this.initState}
    }

    onFieldChange = async <T extends keyof Pick<any, "spvSelected" | "teamSelected" | "amarta_TRACKING_LEAD_AGENT" | "amarta_TRACKING_ORDER_AGENT" | "chery_TRACKING_LEAD_AGENT" | "chery_TRACKING_TEST_DRIVE_AGENT">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "spvSelected":
                currentState.spvSelected = value;
                break;
            case "teamSelected":
                currentState.teamSelected = value;
                break;
            case "amarta_TRACKING_LEAD_AGENT":
                currentState.amarta_TRACKING_LEAD_AGENT = value;
                break;
            case "amarta_TRACKING_ORDER_AGENT":
                currentState.amarta_TRACKING_ORDER_AGENT = value;
                break;
            case "chery_TRACKING_LEAD_AGENT":
                currentState.chery_TRACKING_LEAD_AGENT = value;
                break;
            case "chery_TRACKING_TEST_DRIVE_AGENT":
                currentState.chery_TRACKING_TEST_DRIVE_AGENT = value;
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    async componentDidMount() {
        try {
            await groupServices.setToken(this.state.token);
            const fetchResult = await groupServices.getGroup({type: "supervisor"});
            this.promisedSetState({
                dataListSpv: fetchResult.data.data,
            })
        } catch (e) {
            this.promisedSetState({
                dataListSpv: [],
            })
        }

        try {
            await groupServices.setToken(this.state.token);
            const fetchResult = await groupServices.getGroup({type: "team"});
            this.promisedSetState({
                dataListTeam: fetchResult.data.data,
            })
        } catch (e) {
            this.promisedSetState({
                dataListTeam: [],
            })
        }

        try {
            await trackAgentService.setToken(this.state.token);
            const fetchResult = await trackAgentService.getMasterTrackAgent({companyCode: "amarta"});
            await this.promisedSetState({
                dataTrackingAmarta: fetchResult?.data?.data,
            });
        } catch (e) {
            await this.promisedSetState({
                dataTrackingAmarta: [],
            });
        }

        try {
            await trackAgentService.setToken(this.state.token);
            const fetchResult = await trackAgentService.getMasterTrackAgent({companyCode: "chery"});
            await this.promisedSetState({
                dataTrackingChery: fetchResult?.data?.data,
            });
        } catch (e) {
            await this.promisedSetState({
                dataTrackingChery: [],
            });
        }

        let amarta_TRACKING_LEAD_AGENT = false;
        let amarta_TRACKING_ORDER_AGENT = false;
        let chery_TRACKING_LEAD_AGENT = false;
        let chery_TRACKING_TEST_DRIVE_AGENT = false;
        for (const item of this.state.dataTrackingAmarta ?? []) {
            if (item.type === "TRACKING_LEAD_AGENT") {
                amarta_TRACKING_LEAD_AGENT = item.list_agent.includes(this.props.data.agent_code);
            }
            if (item.type === "TRACKING_ORDER_AGENT") {
                amarta_TRACKING_ORDER_AGENT = item.list_agent.includes(this.props.data.agent_code);
            }
        }

        for (const item of this.state.dataTrackingChery ?? []) {
            if (item.type === "TRACKING_LEAD_AGENT") {
                chery_TRACKING_LEAD_AGENT = item.list_agent.includes(this.props.data.agent_code);
            }
            if (item.type === "TRACKING_TEST_DRIVE_AGENT") {
                chery_TRACKING_TEST_DRIVE_AGENT = item.list_agent.includes(this.props.data.agent_code);
            }
        }

        await this.promisedSetState({
            spvSelected: this.props.data.supervisor_code ?? "",
            teamSelected: (this.props.data.team_list?.length > 0) ? this.props.data.team_list[0] : "",
            amarta_TRACKING_LEAD_AGENT: amarta_TRACKING_LEAD_AGENT,
            amarta_TRACKING_ORDER_AGENT: amarta_TRACKING_ORDER_AGENT,
            chery_TRACKING_LEAD_AGENT: chery_TRACKING_LEAD_AGENT,
            chery_TRACKING_TEST_DRIVE_AGENT: chery_TRACKING_TEST_DRIVE_AGENT,
            processingFetch: false,
        });
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if ((this.props.data.agent_code !== prevProps.data.agent_code)) {
            let amarta_TRACKING_LEAD_AGENT = false;
            let amarta_TRACKING_ORDER_AGENT = false;
            let chery_TRACKING_LEAD_AGENT = false;
            let chery_TRACKING_TEST_DRIVE_AGENT = false;
            for (const item of this.state.dataTrackingAmarta ?? []) {
                if (item.type === "TRACKING_LEAD_AGENT") {
                    amarta_TRACKING_LEAD_AGENT = item.list_agent.includes(this.props.data.agent_code);
                }
                if (item.type === "TRACKING_ORDER_AGENT") {
                    amarta_TRACKING_ORDER_AGENT = item.list_agent.includes(this.props.data.agent_code);
                }
            }

            for (const item of this.state.dataTrackingChery ?? []) {
                if (item.type === "TRACKING_LEAD_AGENT") {
                    chery_TRACKING_LEAD_AGENT = item.list_agent.includes(this.props.data.agent_code);
                }
                if (item.type === "TRACKING_TEST_DRIVE_AGENT") {
                    chery_TRACKING_TEST_DRIVE_AGENT = item.list_agent.includes(this.props.data.agent_code);
                }
            }

            await this.promisedSetState({
                spvSelected: this.props.data.supervisor_code ?? "",
                teamSelected: (this.props.data.team_list?.length > 0) ? this.props.data.team_list[0] : "",
                amarta_TRACKING_LEAD_AGENT: amarta_TRACKING_LEAD_AGENT,
                amarta_TRACKING_ORDER_AGENT: amarta_TRACKING_ORDER_AGENT,
                chery_TRACKING_LEAD_AGENT: chery_TRACKING_LEAD_AGENT,
                chery_TRACKING_TEST_DRIVE_AGENT: chery_TRACKING_TEST_DRIVE_AGENT,
                processingFetch: false,
            });
        }
    }

    onCancel = async () => {
        this.props.onCloseModal({reload: false});
    }

    onSubmit = async () => {
        const dataUpdate = {
            type: "update_user_additional_data",
            uid: this.props.data.uid,
            update_data: {
                supervisor_code: this.state.spvSelected,
                team_code: [this.state.teamSelected],
                tracking: [
                    {
                        company: "amarta",
                        type: "TRACKING_LEAD_AGENT",
                        status: this.state.amarta_TRACKING_LEAD_AGENT
                    },
                    {
                        company: "amarta",
                        type: "TRACKING_ORDER_AGENT",
                        status: this.state.amarta_TRACKING_ORDER_AGENT
                    },
                    {
                        company: "chery",
                        type: "TRACKING_LEAD_AGENT",
                        status: this.state.chery_TRACKING_LEAD_AGENT
                    },
                    {
                        company: "chery",
                        type: "TRACKING_TEST_DRIVE_AGENT",
                        status: this.state.chery_TRACKING_TEST_DRIVE_AGENT
                    }
                ]
            }
        }

        await this.promisedSetState({
            loading: true
        });

        try {
            await userServices.setToken(this.state.token);
            await userServices.updateUserInternal(dataUpdate).then(async successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'Update Success'
                });
                await this.promisedSetState({
                    loading: false,
                });

                this.props.onCloseModal({reload: true});
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            this.setState({
                loading: false,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div style={this.state.processingFetch ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>
                <div style={!this.state.processingFetch ? {} : {display: 'none'}}>

                    <Form.Item>
                        <i className={`small-text-grey`}>Supervisor:</i><br/>
                        <Select
                            style={{float: "left", minWidth: "280px"}}
                            showSearch
                            placeholder="Pilih SPV"
                            optionFilterProp="children"
                            onChange={value => this.onFieldChange("spvSelected", value)}
                            value={this.state.spvSelected}
                        >
                            <Select.Option value={""}>{"Tanpa Supervisor"}</Select.Option>
                            {(this.state.dataListSpv ?? []).map((item: any, i: number) =>
                                <Select.Option key={i} value={item?.supervisor_code}>{item?.supervisor_name}</Select.Option>
                            )}
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <i className={`small-text-grey`}>Team:</i><br/>
                        <Select
                            style={{float: "left", minWidth: "280px"}}
                            showSearch
                            placeholder="Pilih Team"
                            optionFilterProp="children"
                            onChange={value => this.onFieldChange("teamSelected", value)}
                            value={this.state.teamSelected}
                        >
                            <Select.Option value={""}>{"Tanpa Team"}</Select.Option>
                            {(this.state.dataListTeam ?? []).map((item: any, i: number) =>
                                <Select.Option key={i} value={item?.team_code}>{item?.team_name}</Select.Option>
                            )}
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <i className={`small-text-grey`}>Tracking Amarta:</i><br/>
                        <Checkbox checked={this.state.amarta_TRACKING_LEAD_AGENT} onChange={e => this.onFieldChange("amarta_TRACKING_LEAD_AGENT", e.target.checked)}>Tracking Lead Agent</Checkbox><br/>
                        <Checkbox checked={this.state.amarta_TRACKING_ORDER_AGENT} onChange={e => this.onFieldChange("amarta_TRACKING_ORDER_AGENT", e.target.checked)}>Tracking Order Agent</Checkbox><br/>
                        <br/>
                        <i className={`small-text-grey`}>Tracking Chery:</i><br/>
                        <Checkbox checked={this.state.chery_TRACKING_LEAD_AGENT} onChange={e => this.onFieldChange("chery_TRACKING_LEAD_AGENT", e.target.checked)}>Tracking Lead Agent</Checkbox><br/>
                        <Checkbox checked={this.state.chery_TRACKING_TEST_DRIVE_AGENT} onChange={e => this.onFieldChange("chery_TRACKING_TEST_DRIVE_AGENT", e.target.checked)}>Tracking TestDrive Agent</Checkbox><br/>
                    </Form.Item>

                    <div style={{paddingTop: 20, textAlign: "right"}}>
                        <Divider/>
                        <Button loading={this.state.loading} onClick={event => this.onCancel()} style={{marginRight: 7}}>Cancel</Button>
                        <Button loading={this.state.loading} onClick={event => this.onSubmit()} type="primary" style={{marginRight: 7}}>Update</Button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default UpdateAdditionalData;
