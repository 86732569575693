import React, {Component} from 'react';
import AppLayout from "../../component/layouts/AppLatout";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import {UserOrderStates} from "./types/UserOrderTypes";
import {userServices} from "../../services/amartaVip/UserService";
import {Select, Form, Input, Button, Empty, Spin, Row, Col, DatePicker, Table, Tooltip, Tag, Checkbox, Card, Divider} from "antd";
import DetailUser from "../../component/detailData/DetailUser";
import dayjs from "dayjs";
import {CopyOutlined} from "@ant-design/icons";
import ShoppingOutlined from "@ant-design/icons/ShoppingOutlined";

export interface UserOrderProps {
}

class UserOrder extends Component<UserOrderProps & any, UserOrderStates> {
    private cookies = new Cookies();

    constructor(props: UserOrderProps & any) {
        super(props);

        this.state = {
            token: this.cookies.get('_vip-t') ?? "",
            processingFetchData: false,
            onlyOrderTransaction: false,
            processingFetchTableData: false,
            searchType: "IDCARD",
            searchField: "",
            // searchType: "AGENTCODE",
            // searchField: "999999",
            startDate: dayjs().startOf('month'),
            dataOrderUser: {},
            dataDetailOrder: [],
            resumeOrderCount: 0,
            resumeOrderData: [],
        }
    }

    onAreaChange = async (data: any) => {
        console.log(data)
    }

    fetchUserData = async () => {
        if (this.state.searchType && this.state.searchField) {
            await this.promisedSetState({
                processingFetchData: true,
                dataOrderUser: {},
            });

            try {
                await userServices.setToken(this.state.token);
                const fetchResult = await userServices.getDetailUser({type: this.state.searchType, searchText: this.state.searchField});
                await this.promisedSetState({
                    processingFetchData: false,
                    dataOrderUser: fetchResult?.data?.data,
                });
            } catch (e) {
                await this.promisedSetState({
                    processingFetchData: false,
                    dataOrderUser: {},
                });
            }

            if (this.state.dataOrderUser.uid) {
                const startDate = dayjs(this.state.startDate).format("YYYY-MM-DD");
                const lastDayOfMonth = dayjs(startDate).endOf('month').format('YYYY-MM-DD');

                await this.promisedSetState({
                    processingFetchTableData: true,
                    dataDetailOrder: [],
                });

                try {
                    await userServices.setToken(this.state.token);
                    const fetchResult = await userServices.getListUserOrder({
                        startDate: startDate,
                        endDate: lastDayOfMonth,
                        uid: this.state.dataOrderUser.uid,
                        internal: "true",
                        transactionStatus: (this.state.onlyOrderTransaction) ? "order" : ""
                    });

                    const sortedData: any = fetchResult?.data?.data;
                    await sortedData.sort((a: any, b: any) => (new Date(b.transaction_time)).getTime() - (new Date(a.transaction_time)).getTime());

                    let resumeOrderCount = 0
                    let resumeOrderData: any = []
                    if (this.state.onlyOrderTransaction) {
                        const resumeOrderObject = fetchResult?.data?.meta?.resume_order ?? []
                        const newArray = Object.entries(resumeOrderObject).map(([order_date, count]) => ({order_date, count}));

                        resumeOrderCount = fetchResult?.data?.meta?.count
                        resumeOrderData = newArray
                    }

                    await this.promisedSetState({
                        processingFetchTableData: false,
                        dataDetailOrder: sortedData,
                        resumeOrderCount: resumeOrderCount,
                        resumeOrderData: resumeOrderData,
                    });
                } catch (e) {
                    await this.promisedSetState({
                        processingFetchTableData: false,
                        dataDetailOrder: [],
                        resumeOrderCount: 0,
                        resumeOrderData: [],
                    });
                }

            }
        }
    }

    async componentDidMount() {

    }

    copyToClipboard = async (data: any) => {
        await navigator.clipboard.writeText(data);
    }

    onFieldChange = async <T extends keyof Pick<any, "searchType" | "onlyOrderTransaction" | "searchField" | "startDate">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "searchType":
                currentState.searchType = value;
                currentState.searchField = "";
                break;
            case "searchField":
                currentState.searchField = value;
                break;
            case "startDate":
                currentState.startDate = value;
                break;
            case "onlyOrderTransaction":
                currentState.onlyOrderTransaction = value;
                currentState.dataDetailOrder = [];
                currentState.resumeOrderCount = 0;
                currentState.resumeOrderData = [];
                break;
        }
        await this.promisedSetState({
            ...currentState,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <AppLayout noPadNoMar={true} onClickClass={this.onAreaChange} breadcrumbPath={`UserVIP`} breadcrumbMenu={`User Order`} defaultSelectedKeys={['m-orderuservip']} defaultOpenKeys={['sm-uservip', 'sm-data-uservip']}>
                    <div className={`p-5`}>
                        <Form layout={`inline`} style={{marginTop: -3}}>
                            <Form.Item>
                                <i className={`small-text-grey`}>Search Type:</i><br/>
                                <Select
                                    style={{minWidth: 200}}
                                    showSearch
                                    placeholder="Choose SearchType"
                                    optionFilterProp="children"
                                    onChange={value => this.onFieldChange("searchType", value)}
                                    value={this.state.searchType}
                                >
                                    <Select.Option key={`IDCARD`} value={`IDCARD`}>{`ID Card`}</Select.Option>
                                    <Select.Option key={`PHONENUMBER`} value={`PHONENUMBER`}>{`Phone Number`}</Select.Option>
                                    <Select.Option key={`EMAIL`} value={`EMAIL`}>{`Email`}</Select.Option>
                                    <Select.Option key={`AGENTCODE`} value={`AGENTCODE`}>{`Agent / Affiliate Code`}</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <i className={`small-text-grey`}>Search Field:</i><br/>
                                <Input value={this.state.searchField} onChange={event => this.onFieldChange("searchField", event.target.value)} placeholder="Search Field"/>
                            </Form.Item>
                            <Form.Item className={'padTop-10'}>
                                <i className={`small-text-grey`}>Periode:</i><br/>
                                <DatePicker picker="month" defaultValue={dayjs(this.state.startDate, 'YYYY-MM')} onChange={event => this.onFieldChange("startDate", event)} format={'YYYY-MM'}/>
                            </Form.Item>
                            <Form.Item>
                                <Checkbox style={{marginTop: 25}} checked={this.state.onlyOrderTransaction} onChange={e => this.onFieldChange("onlyOrderTransaction", e.target.checked)}>Resume Order</Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <i className={`small-text-grey`}>&nbsp;</i><br/>
                                <Button loading={this.state.processingFetchData} onClick={this.fetchUserData} type={"primary"}> Cari Data </Button>
                            </Form.Item>
                        </Form>
                    </div>

                    <div style={this.state.processingFetchData ? {} : {display: 'none'}} className={`spinCenter`}>
                        <Spin size="large"/>
                    </div>

                    <div style={(!this.state.dataOrderUser?.name && !this.state.processingFetchData) ? {} : {display: 'none'}}>
                        <Empty/>
                    </div>

                    <div className={`p-5`} style={(this.state.dataOrderUser?.name) ? {} : {display: 'none'}}>
                        <Row>
                            <Col xs={24} xl={9} style={{padding: 5}}>
                                <DetailUser dataDetailUser={this.state.dataOrderUser} fullCol={true} showUserType={true} showIdCard={true}/>
                            </Col>
                            <Col xs={24} xl={15} style={{padding: 5}}>
                                <div style={(this.state.onlyOrderTransaction && this.state.resumeOrderCount) ? {} : {display: 'none'}}>
                                    <Row>
                                        <Col xs={24} xl={12} style={{padding: 10}}>
                                            <Card size="small" hoverable={true}>
                                                <b style={{fontSize: "100%", color: "gray"}}><ShoppingOutlined/> Total Order</b>
                                                <br/>
                                                <b style={{fontSize: "180%", paddingLeft: 20}}>{this.state.resumeOrderCount ?? 0}</b>
                                                <i style={{fontSize: "80%", color: "gray"}}> Offer dengan status order</i>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Table
                                        pagination={false}
                                        size={"small"}
                                        loading={this.state.processingFetchTableData}
                                        dataSource={this.state.resumeOrderData}
                                        rowKey={'order_date'}
                                        columns={[
                                            {
                                                title: 'Order Time',
                                                dataIndex: 'order_date',
                                                render: ((value: string, row: any) => {
                                                    return <React.Fragment>
                                                        <span className={`font-90`}>{value}</span>
                                                    </React.Fragment>
                                                })
                                            },
                                            {
                                                title: 'Order Count',
                                                dataIndex: 'count',
                                                render: ((value: string, row: any) => {
                                                    return <React.Fragment>
                                                        <span className={`font-90`}>{value}</span>
                                                    </React.Fragment>
                                                })
                                            }
                                        ]}
                                    />
                                    <Divider/>
                                </div>


                                <Table
                                    pagination={false}
                                    size={"small"}
                                    loading={this.state.processingFetchTableData}
                                    dataSource={this.state.dataDetailOrder}
                                    rowKey={'transaction_code'}
                                    columns={[
                                        {
                                            title: 'Organization',
                                            dataIndex: 'source',
                                            render: ((value: string, row: any) => {
                                                return <React.Fragment>
                                                    <span className={`font-90`}>{value}</span>
                                                </React.Fragment>
                                            })
                                        },
                                        {
                                            title: 'Transaction Code',
                                            dataIndex: 'transaction_code',
                                            render: ((value: string, row: any) => {
                                                return <React.Fragment>
                                                    <span className={`font-90`}>{value}</span>
                                                    <Tooltip title="Click untuk copy code" color={'cyan'}>
                                                        &nbsp; <CopyOutlined className={'small-avatar-link'} onClick={event => this.copyToClipboard(value)}/>
                                                    </Tooltip><br/>
                                                </React.Fragment>
                                            })
                                        },
                                        {
                                            title: 'Transaction Time',
                                            dataIndex: 'transaction_time',
                                            render: ((value: string, row: any) => {
                                                return <React.Fragment>
                                                    <span className={`font-90`}>{value?.slice(0, 16)?.replace("T", " ")}</span>
                                                </React.Fragment>
                                            })
                                        },
                                        {
                                            title: 'Type',
                                            dataIndex: 'transaction_type',
                                            render: ((value: string, row: any) => {
                                                let transactionType: any = value;
                                                if (value === "cash") {
                                                    transactionType = <Tag style={{fontSize: "70%", display: "inline"}} color="#87d068">Cash</Tag>;
                                                } else if (value === "credit") {
                                                    transactionType = <Tag style={{fontSize: "70%", display: "inline"}} color="#ff5500">Credit</Tag>
                                                }
                                                return <React.Fragment>
                                                    <span className={`font-90`}>{transactionType}</span>
                                                </React.Fragment>
                                            })
                                        },
                                        {
                                            title: 'Status',
                                            dataIndex: 'transaction_status',
                                            render: ((value: string, row: any) => {
                                                let offerStatus: any = value;
                                                if (value === "open") {
                                                    offerStatus = <Tag style={{fontSize: "70%", display: "inline"}} color="#108ee9">OnProcess</Tag>;
                                                } else if (value === "cancel") {
                                                    offerStatus = <Tag style={{fontSize: "70%", display: "inline"}} color="#f50">Cacnel</Tag>
                                                } else if (value === "order") {
                                                    offerStatus = <Tag style={{fontSize: "70%", display: "inline"}} color="#87d068">Ordered</Tag>;
                                                } else if (value === "handover") {
                                                    offerStatus = <Tag style={{fontSize: "70%", display: "inline"}} color="#7D50C7">Handover</Tag>;
                                                }
                                                return <React.Fragment>
                                                    <span className={`font-90`}>{offerStatus}</span>
                                                </React.Fragment>
                                            })
                                        }
                                    ]}
                                />
                            </Col>
                        </Row>
                    </div>

                </AppLayout>
            </React.Fragment>
        );
    }
}

export default withAuth(UserOrder, 19);