import React, {Component} from "react";
import Cookies from "universal-cookie";
import {Image, Row, Col, Divider, Card, Timeline, Avatar, Tooltip, Modal, Button} from "antd";
import {CheckCircleTwoTone, HistoryOutlined, QrcodeOutlined, ContactsOutlined, IdcardOutlined, TabletOutlined, AuditOutlined} from '@ant-design/icons';
import InfoCircleTwoTone from "@ant-design/icons/InfoCircleTwoTone";
import {offerGeneralApiService} from "../../services/offer/OfferGeneralApiService";
import {userServices} from "../../services/amartaVip/UserService";
import {ocrApiService} from "../../services/trimitraCdn/OcrApiService";
import DetailUserQr from "./DetailUserQr";
import DetailUserLog from "./DetailUserLog";
import SendTelegramMessage from "../pageSection/SendTelegramMessage";
import DetailAppboardingAffiliate from "./DetailAppboardingAffiliate";
import DetailUserDevice from "./DetailUserDevice";
import DetailUserPayroll from "./DetailUserPayroll";

class DetailUser extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        this.initState = {
            token: this.cookies.get('_vip-t') ?? "",
            submitting: false,
            showBankAccount: false,
            detailBankAccount: {},
            indexBankAccount: 0,
            modalUserLog: false,
            modalUserDevice: false,
            modalUserPayroll: false,
            modalUserQr: false,
            modalAppboadringAffiliate: false,
        }
        this.state = {...this.initState}
    }

    reScanIdCard = async () => {
        await this.promisedSetState({submitting: true});
        let ocrResult = {}
        try {
            const dataOcr = await ocrApiService.getImageIdCardNumber({image_url: this.props.dataDetailUser?.id_card?.id_card_image});
            ocrResult = dataOcr.data.data;
        } catch (e) {
            this.setState({freezeStatus: false});
            return Modal.error({title: 'Proses Gagal', content: "KTP tidak terdeteksi, mohon upload image ktp secara utuh dan jelas"});
        }

        const dataVerify = {
            type: "update_biodata_verify_idcard",
            uid: this.props.dataDetailUser?.uid,
            id_card_ocr: ocrResult,
        }

        try {
            await userServices.setToken(this.state.token);
            await userServices.updateUserInternal(dataVerify).then(async successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'Verify Success'
                });
                await this.promisedSetState({
                    submitting: false,
                    showIdCardOcrData: false,
                });

                await this.props.onSuccesVerifyProps({refresh_data: true});
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            this.setState({
                submitting: false,
            });
        }

    }

    reCheckAPiBankAccount = async () => {
        await this.promisedSetState({submitting: true});

        offerGeneralApiService.setToken(this.props.token);
        const validateBankResult = await offerGeneralApiService.validateBank({bankCode: this.state.detailBankAccount?.bank_code, accountNumber: this.state.detailBankAccount?.account_number});
        if (validateBankResult?.beneficiaryAccountName?.toUpperCase()?.trim() === this.props.dataDetailUser?.id_card?.full_name?.toUpperCase()?.trim()) {
            const dataVerify = {
                type: "update_biodata_verify_bankaccount",
                uid: this.props.dataDetailUser?.uid,
                account_number: this.state.detailBankAccount.account_number,
                bank_code: this.state.detailBankAccount.bank_code,
                verified: true,
                ocr_result: {},
                verified_bank_account_name: validateBankResult.beneficiaryAccountName.toUpperCase()
            }

            try {
                await userServices.setToken(this.state.token);
                await userServices.updateUserInternal(dataVerify).then(async successData => {
                    Modal.success({
                        title: 'Proses Sukses',
                        content: 'Verify Success'
                    });
                    await this.promisedSetState({
                        submitting: false,
                        showBankAccount: false,
                    });

                    await this.props.onSuccesVerifyProps({refresh_data: true});
                });
            } catch (e) {
                Modal.error({
                    title: 'Proses Gagal',
                    content: 'Error :' + e
                });
                this.setState({
                    submitting: false,
                });
            }
        } else {
            this.setState({
                submitting: false,
            });
            Modal.error({
                title: 'Proses Gagal',
                content: "Nama Bank dan KTP Berbeda, Nama KTP: " + this.props.dataDetailUser?.id_card?.full_name?.toUpperCase() + " Nama pada Bank: " + validateBankResult.beneficiaryAccountName.toUpperCase()
            });
        }
    }

    reScanBankAccount = async () => {
        await this.promisedSetState({
            submitting: true
        });

        let ocrResult = {};
        let isBankAccountVerified = false;
        try {
            const responseOcr = await ocrApiService.getImageBankStatement({image_url: this.state.detailBankAccount.url_image, bank_account_number: this.state.detailBankAccount.account_number, bank_account_name: this.state.detailBankAccount.account_name});
            ocrResult = {
                result: {
                    bank_account_name: responseOcr.data.data.bank_account_name,
                    bank_account_number: responseOcr.data.data.bank_account_number
                },
                payload: responseOcr.data.data.payload
            }

            if (responseOcr.data.data.bank_account_name === this.state.detailBankAccount.account_name && responseOcr.data.data.bank_account_number === this.state.detailBankAccount.account_number) {
                isBankAccountVerified = true;
            }

            let newDetailBankAccount = {...this.state.detailBankAccount};
            newDetailBankAccount.ocr_result = responseOcr.data.data;
            newDetailBankAccount.ocr_result.result = responseOcr.data.data;

            this.setState({
                detailBankAccount: newDetailBankAccount
            });

            await this.promisedSetState({
                submitting: false
            });
        } catch (e) {
            console.log(e);
            await this.promisedSetState({
                submitting: false
            });
        }

        if (isBankAccountVerified) {
            const dataVerify = {
                type: "update_biodata_verify_bankaccount",
                uid: this.props.dataDetailUser?.uid,
                account_number: this.state.detailBankAccount.account_number,
                bank_code: this.state.detailBankAccount.bank_code,
                verified: true,
                ocr_result: ocrResult,
            }

            await this.promisedSetState({
                submitting: true
            });

            try {
                await userServices.setToken(this.state.token);
                await userServices.updateUserInternal(dataVerify).then(async successData => {
                    Modal.success({
                        title: 'Proses Sukses',
                        content: 'Verify Success'
                    });
                    await this.promisedSetState({
                        submitting: false,
                        showBankAccount: false,
                    });

                    await this.props.onSuccesVerifyProps({refresh_data: true});
                });
            } catch (e) {
                Modal.error({
                    title: 'Proses Gagal',
                    content: 'Error :' + e
                });
                this.setState({
                    submitting: false,
                });
            }

        } else {
            Modal.error({
                title: 'Informasi',
                content: 'OCR sukses reScan namun hasil dianggap tidak sesuai'
            });
        }
    }

    verifyBankAccount = async () => {
        await this.promisedSetState({
            submitting: true
        });

        const dataVerify = {
            type: "update_biodata_verify_bankaccount",
            uid: this.props.dataDetailUser?.uid,
            account_number: this.state.detailBankAccount.account_number,
            bank_code: this.state.detailBankAccount.bank_code,
            verified: true
        }

        try {
            await userServices.setToken(this.state.token);
            await userServices.updateUserInternal(dataVerify).then(async successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'Verify Success'
                });
                await this.promisedSetState({
                    submitting: false,
                    showBankAccount: false,
                });

                await this.props.onSuccesVerifyProps({refresh_data: true});
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            this.setState({
                submitting: false,
            });
        }
    }

    showUserHistory = async () => {
        this.setState({modalUserLog: true});
    }

    showUserDevice = async () => {
        this.setState({modalUserDevice: true});
    }

    showUserPayroll = async () => {
        this.setState({modalUserPayroll: true});
    }

    showUserOnboardingPercentage = async () => {
        this.setState({modalAppboadringAffiliate: true});
    }

    showUserQr = async () => {
        this.setState({modalUserQr: true});
    }

    handleOkDefaultAccount = async () => {
        const dataVerify = {
            type: "update_default_bankaccount",
            uid: this.props.dataDetailUser?.uid,
            account_number: this.state.detailBankAccount.account_number,
        }

        await this.promisedSetState({
            submitting: true
        });

        try {
            await userServices.setToken(this.state.token);
            await userServices.updateUserInternal(dataVerify).then(async successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'Update Success'
                });
                await this.promisedSetState({
                    modalConfirmUpdateDefaultBank: false,
                    submitting: false,
                    showBankAccount: false,
                });

                await this.props.onSuccesVerifyProps({refresh_data: true});
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Error :' + e
            });
            this.setState({
                submitting: false,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {

        let verifiedIdCard = this.props.dataDetailUser?.id_card_verified;
        if (!verifiedIdCard) {
            const idCardNumber = this.props.dataDetailUser?.id_card?.id_card_number;
            const idCardName = this.props.dataDetailUser?.id_card?.full_name?.toUpperCase()?.replace(/[^a-zA-Z\s]/g, "");
            const idCardNumberOcr = this.props.dataDetailUser?.id_card_ocr?.id_card_number;
            const idCardNameOcr = this.props.dataDetailUser?.id_card_ocr?.name?.toUpperCase()?.replace(/[^a-zA-Z\s]/g, "");
            if ((idCardNumber === idCardNumberOcr) && (idCardName === idCardNameOcr)) {
                verifiedIdCard = true;
            }
        }

        return (
            <React.Fragment>
                <Row style={(this.props.showUserType) ? {} : {display: 'none'}}>
                    <Col xs={4} xl={(this.props.fullCol) ? 2 : 1} style={{paddingTop: 20, textAlign: "center"}}>
                        <Avatar style={(!this.props.dataDetailUser?.selfie_image) ? {} : {display: 'none'}}>{this.props.dataDetailUser?.name?.charAt(0)?.toUpperCase()}</Avatar>
                        <a href={this.props.dataDetailUser?.selfie_image} style={(this.props.dataDetailUser?.selfie_image) ? {} : {display: 'none'}} target={"_blank"} rel="noreferrer">
                            <Avatar src={this.props.dataDetailUser?.selfie_image} style={{cursor: "pointer"}}/>
                        </a>
                    </Col>
                    <Col xs={18} xl={18} style={{padding: 5}}>
                        <span className={`gray-80`}>Name: <span style={{paddingLeft: 38, color: "#bb1919", fontWeight: 500}}>{this.props.dataDetailUser?.name}</span></span><br/>
                        <span className={`gray-80`}>type: <span style={{paddingLeft: 45, color: "#bb1919", fontWeight: 500}}>{this.props.dataDetailUser?.user_type}</span></span><br/>
                        <span className={`gray-80`}>AgentCode: <span style={{paddingLeft: 10, color: "#bb1919", fontWeight: 500}}>{this.props.dataDetailUser?.agent_code}</span></span>
                    </Col>
                </Row>
                <Row style={(this.props.showIdCard) ? {} : {display: 'none'}}>
                    <Col xs={24} xl={(this.props.fullCol) ? 24 : 8} style={{padding: 5}}>
                        <Divider orientation="left" plain style={{paddingLeft: 10, paddingRight: 40,}}>Data Kontak</Divider>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Nomor Telpon:</Col>
                            <Col xs={24} xl={18}>
                                {this.props.dataDetailUser?.phones?.map((item: any, x: number) =>
                                    <div key={x} style={{paddingRight: 10}}>{item?.phone}</div>
                                )}
                            </Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Email:</Col>
                            <Col xs={24} xl={18}>
                                {this.props.dataDetailUser?.emails?.map((item: any, x: number) =>
                                    <div key={x} style={{paddingRight: 10}}>{item?.email}</div>
                                )}
                            </Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Default Org:</Col>
                            <Col xs={24} xl={18}>
                                {this.props.dataDetailUser?.default_organization_code ?? " - "}
                            </Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Telegram ID:</Col>
                            <Col xs={24} xl={18}>
                                <div style={(!this.props.allowSendTelegram) ? {} : {display: 'none'}}>
                                    {this.props.dataDetailUser?.telegram_id ?? " - "}
                                </div>
                                <div style={(this.props.allowSendTelegram && this.props.dataDetailUser?.telegram_id) ? {} : {display: 'none'}}>
                                    <SendTelegramMessage telegramId={this.props.dataDetailUser?.telegram_id}/>
                                </div>
                            </Col>
                        </Row>

                        <Divider orientation="left" plain style={{paddingLeft: 10, paddingRight: 40,}}>Data KTP</Divider>
                        <Row className={`pt-5`} onClick={() => this.setState({showIdCardOcrData: true})}>
                            <Col xs={24} xl={6} className={`col-grey`}>Nomor KTP:</Col>
                            <Col xs={24} xl={18}>
                                <span style={{color: "#1677ff", cursor: "pointer"}}>
                                    <span style={verifiedIdCard ? {} : {display: 'none'}}>
                                        <Tooltip title="Verified ID Card"> &nbsp; <CheckCircleTwoTone twoToneColor="#52c41a"/></Tooltip>
                                    </span> &nbsp;
                                    {this.props.dataDetailUser?.id_card?.id_card_number} &nbsp;
                                    <IdcardOutlined/>
                                </span>
                            </Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Nama KTP:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.id_card?.full_name}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Tempat Lahir:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.id_card?.birth_place}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Tanggal Lahir:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.id_card?.birth_date}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Status Perkawinan:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.id_card?.marital_status}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Pekerjaan:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.id_card?.occupation}</Col>
                        </Row>

                        <Divider orientation="left" plain style={{paddingLeft: 10, paddingRight: 40,}}>Data Alamat KTP</Divider>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Provinsi:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.address?.province_name}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Kota / Kabupaten:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.address?.city_name}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Kelurahan:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.address?.district_name}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Kecamatan:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.address?.sub_district_name}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Kode Pos:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.address?.postal_code}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>RW / RT:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.address?.hamlet} / {this.props.dataDetailUser?.address?.neighbourhood}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Alamat Lengkap:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.address?.full_address}</Col>
                        </Row>
                    </Col>
                    <Col xs={24} xl={(this.props.fullCol) ? 24 : 8} style={{padding: 5}}>
                        <Divider orientation="left" plain style={{paddingLeft: 10, paddingRight: 40,}}>Data Alamat Saat Ini</Divider>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Provinsi:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.current_address?.province_name}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Kota / Kabupaten:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.current_address?.city_name}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Kelurahan:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.current_address?.district_name}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Kecamatan:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.current_address?.sub_district_name}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Kode Pos:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.current_address?.postal_code}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>RW / RT:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.current_address?.hamlet} / {this.props.dataDetailUser?.current_address?.neighbourhood}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Alamat Lengkap:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.current_address?.full_address}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Kepemilikan Rumah:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.current_address?.housing_status}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Gmap Url:</Col>
                            <Col xs={24} xl={18}>
                                {this.props.dataDetailUser?.current_address?.gmap_url ? (
                                    <a href={this.props.dataDetailUser.current_address.gmap_url} target="_blank" rel="noopener noreferrer">
                                        Lihat Map
                                    </a>
                                ) : (
                                    "-"
                                )}
                            </Col>
                        </Row>

                        <Divider orientation="left" plain style={{paddingLeft: 10, paddingRight: 40,}}>Data Coverage Area</Divider>
                        <ul>
                            {this.props.dataDetailUser?.coverage_area?.map((item: any, x: number) =>
                                <li key={x}>{item?.province_name?.toString()} {item?.city_name?.toString()}</li>
                            )}
                        </ul>
                    </Col>

                    <Col xs={24} xl={(this.props.fullCol) ? 24 : 8} style={{padding: 5}}>
                        <Divider orientation="left" plain style={{paddingLeft: 10, paddingRight: 40,}}>Latest Recognition</Divider>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Face:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.latest_recognition?.face_recognition ?? "-"}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Fingerprint:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.latest_recognition?.fingerprint_recognition ?? "-"}</Col>
                        </Row>
                        <Divider orientation="left" plain style={{paddingLeft: 10, paddingRight: 40, paddingTop: 7}}>Latest Location</Divider>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>PointLocation:</Col>
                            <Col xs={24} xl={18}>
                                <span style={(this.props.dataDetailUser?.latest_location?.location?.latitude) ? {} : {display: 'none'}}>
                                    <a target={"_blank"} rel={`noreferrer`} href={`https://www.google.com/maps/place/${this.props.dataDetailUser?.latest_location?.location?.latitude},${this.props.dataDetailUser?.latest_location?.location?.longitude}`}>{this.props.dataDetailUser?.latest_location?.location?.latitude}, {this.props.dataDetailUser?.latest_location?.location?.longitude}</a>
                                </span>
                            </Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Time:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.latest_location?.time?.slice(0, 16)?.replace("T", " ") ?? "-"}</Col>
                        </Row>


                        <Divider orientation="left" plain style={{paddingLeft: 10, paddingRight: 40, paddingTop: 7}}>Latest Triforce App</Divider>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>First Login:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.latest_triforce_activity?.first_triforce_login?.slice(0, 16)?.replace("T", " ") ?? "-"}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Latest Login:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.latest_triforce_activity?.latest_triforce_login?.slice(0, 16)?.replace("T", " ") ?? "-"}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Latest Seen Page:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.latest_triforce_activity?.latest_activity_seen_page}</Col>
                        </Row>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Latest Seen Page:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.latest_triforce_activity?.latest_activity_time_seen_page?.slice(0, 16)?.replace("T", " ") ?? "-"}</Col>
                        </Row>

                        <Divider/>
                        <Button style={{margin: 5, width: 150}} onClick={this.showUserDevice} type={"primary"}> <TabletOutlined/> Log Device </Button> <br/>
                        <Button style={{margin: 5, width: 150}} onClick={this.showUserHistory} type={"primary"}> <HistoryOutlined/> Log History </Button> <br/>
                        <Button style={{margin: 5, width: 150}} onClick={this.showUserPayroll} type={"primary"}> <AuditOutlined/> Log Payroll </Button> <br/>
                        <Button style={{margin: 5, width: 150}} onClick={this.showUserQr} type={"primary"}> <QrcodeOutlined/> User QR </Button><br/>
                        <Button style={{margin: 5, width: 250}} onClick={this.showUserOnboardingPercentage} type={"primary"}> <ContactsOutlined/> Onboarding Affiliate Contact </Button>
                    </Col>
                </Row>
                <Row style={(this.props.showOtherData) ? {} : {display: 'none'}}>
                    <Col xs={24} xl={8} style={{padding: 5}}>
                        <Divider orientation="left" plain>Data Dokumen</Divider>
                        {this.props.dataDetailUser?.documents?.map((item: any, x: number) =>
                            <Col key={x} className={`p-2`} xxl={{span: 24}} xl={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                <Card hoverable={true} bodyStyle={{padding: 7}} style={(item?.type === "cv") ? {display: 'none'} : {}}>
                                    <Row>
                                        <Col xl={{span: 3}} lg={{span: 2}} md={{span: 4}} sm={{span: 4}} xs={{span: 4}}>
                                            <Image style={{width: 30, paddingTop: 5}} alt="image not found" fallback={`https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/vip-amarta.png`} src={item?.document_image}/>
                                        </Col>
                                        <Col xl={{span: 20}} lg={{span: 20}} md={{span: 17}} sm={{span: 17}} xs={{span: 17}} style={{fontSize: "85%"}}>
                                            {(() => {
                                                if (item?.type === "family_register") {
                                                    return (
                                                        <React.Fragment>
                                                            <span style={{fontWeight: 500}}>KK</span><br/>
                                                        </React.Fragment>
                                                    )
                                                }
                                                if (item?.type === "driving_license_a") {
                                                    return (
                                                        <React.Fragment>
                                                            <span style={{fontWeight: 500}}>SIM A</span><br/>
                                                        </React.Fragment>
                                                    )
                                                } else if (item?.type === "driving_license_c") {
                                                    return (
                                                        <React.Fragment>
                                                            <span style={{fontWeight: 500}}>SIM C</span><br/>
                                                        </React.Fragment>
                                                    )
                                                }
                                                if (item?.type === "driving_license_b1") {
                                                    return (
                                                        <React.Fragment>
                                                            <span style={{fontWeight: 500}}>SIM B1</span><br/>
                                                        </React.Fragment>
                                                    )
                                                }
                                                if (item?.type === "driving_license_b2") {
                                                    return (
                                                        <React.Fragment>
                                                            <span style={{fontWeight: 500}}>SIM B2</span><br/>
                                                        </React.Fragment>
                                                    )
                                                }
                                                if (item?.type === "driving_license_d") {
                                                    return (
                                                        <React.Fragment>
                                                            <span style={{fontWeight: 500}}>SIM D</span><br/>
                                                        </React.Fragment>
                                                    )
                                                } else {
                                                    return (
                                                        <span> <br/> </span>
                                                    )
                                                }
                                            })()}
                                            <span className={`text-small-grey-nopadding`}>{item?.document_number}</span>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        )}
                    </Col>

                    <Col xs={24} xl={8} style={{padding: 5}}>
                        <Divider orientation="left" plain>Rekening Bank</Divider>
                        {this.props.dataDetailUser?.bank_accounts?.map((item: any, x: number) =>
                            <Col key={x} className={`p-2`} xxl={{span: 24}} xl={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                <Card onClick={() => this.setState({showBankAccount: true, detailBankAccount: item, indexBankAccount: x})} hoverable={true} bordered={true} key={x} bodyStyle={{padding: 7}}>
                                    <Row>
                                        <Col xl={{span: 3}} lg={{span: 2}} md={{span: 4}} sm={{span: 4}} xs={{span: 4}}>
                                            <Image preview={false} style={{width: 30, paddingTop: 5}} alt="image not found" fallback={`https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/vip-amarta.png`} src={item?.url_image}/>
                                        </Col>
                                        <Col xl={{span: 20}} lg={{span: 20}} md={{span: 17}} sm={{span: 17}} xs={{span: 17}} style={{fontSize: "85%"}}>
                                            <span style={{fontWeight: 500}}>{item?.bank_name}</span>
                                            <span style={item.verified ? {} : {display: 'none'}}><Tooltip title="Verified Bank Account"> &nbsp; <CheckCircleTwoTone twoToneColor="#52c41a"/></Tooltip></span><br/>
                                            <span className={`text-small-grey-nopadding`}>{item?.account_number} - {item?.account_name?.toUpperCase()}</span>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        )}
                    </Col>

                    <Col xs={24} xl={8} style={{padding: 5}}>
                        <Divider orientation="left" plain>Media Sosial</Divider>
                        {this.props.dataDetailUser?.social_media_accounts?.map((item: any, x: number) =>
                            <Col key={x} className={`p-2`} xxl={{span: 24}} xl={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                <Card hoverable={true} bordered={true} key={x} bodyStyle={{padding: 7}}>
                                    <Row>
                                        <Col xl={{span: 3}} lg={{span: 2}} md={{span: 4}} sm={{span: 4}} xs={{span: 4}}>
                                            <Image style={{width: 30, paddingTop: 5}} alt="youtube" src={`../assets/img/${item?.media}.png`}/>
                                        </Col>
                                        <Col xl={{span: 20}} lg={{span: 20}} md={{span: 17}} sm={{span: 17}} xs={{span: 17}} style={{fontSize: "85%"}}>
                                            <span style={item?.monitoring === true ? {} : {display: 'none'}}>
                                                <Tooltip title="Monitoring Account"> &nbsp; <InfoCircleTwoTone twoToneColor="#52c41a"/></Tooltip>
                                            </span>
                                            <i style={{paddingLeft: 10}}>{item?.user_account}</i><br/>
                                            <a href={item?.url} target={`_blank`}>{item?.url}</a>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        )}
                    </Col>

                    <Col xs={24} xl={12} style={{padding: 5}}>
                        <Divider orientation="left" plain>Riwayat Pendidikan</Divider>
                        <Timeline className={`pt-10`}>
                            {this.props.dataDetailUser?.educational_background?.map((item: any, x: number) =>
                                <Timeline.Item key={x}>
                                    <span style={{fontWeight: 500}}>{item?.degree_name}</span> - <span>{item?.institution_name}</span><br/>
                                    <span className={`text-small-grey-nopadding`}>{item?.field_of_study} {item?.gpa}</span><br/>
                                    <span className={`text-small-grey-nopadding`}>{item?.institution_location}, FROM: {item?.start_date} UNTIL: {item?.end_date}</span><br/>
                                    <span className={`text-small-grey-nopadding`}>{item?.notes}</span>
                                </Timeline.Item>
                            )}
                        </Timeline>
                    </Col>

                    <Col xs={24} xl={12} style={{padding: 5}}>
                        <Divider orientation="left" plain>Riwayat Pekerjaan</Divider>
                        <Timeline className={`pt-10`}>
                            {this.props.dataDetailUser?.employment_background?.map((item: any, x: number) =>
                                <Timeline.Item key={x}>
                                    <span style={{fontWeight: 500}}>{item?.employer}</span> - <span>{item?.job_position}</span> <i style={item?.currently_work_here ? {} : {display: 'none'}} className={`text-small-red-nopadding`}>(Masih Bekerja ditempat ini)</i> <br/>
                                    <span className={`text-small-grey-nopadding`}>{item?.job_description}</span><br/>
                                    <span className={`text-small-grey-nopadding`}>{item?.employer_location}, FROM: {item?.start_date} UNTIL: {item?.end_date}</span><br/>
                                    <span className={`text-small-grey-nopadding`}>{item?.notes}</span>
                                </Timeline.Item>
                            )}
                        </Timeline>
                    </Col>

                    <Col xs={24} xl={24} style={(this.props.dataDetailUser?.reference) ? {marginTop: -10} : {display: 'none'}}>
                        <Divider orientation="left" plain>Referensi</Divider>
                        <Row>
                            {this.props.dataDetailUser?.reference?.map((item: any, x: number) =>
                                <Col key={x} className={`p-2`} xxl={{span: 8}} xl={{span: 8}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                                    <Card hoverable={true} bodyStyle={{padding: 7}}>
                                        <Row>
                                            <Col xl={{span: 3}} lg={{span: 2}} md={{span: 4}} sm={{span: 4}} xs={{span: 4}}>
                                                <Image style={{width: 30, paddingTop: 5}} alt="image not found" fallback={`https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/vip-amarta.png`} src={item?.image_url}/>
                                            </Col>
                                            <Col xl={{span: 20}} lg={{span: 20}} md={{span: 17}} sm={{span: 17}} xs={{span: 17}} style={{fontSize: "85%"}}>
                                                {(() => {
                                                    return (
                                                        <React.Fragment>
                                                            <span style={{fontWeight: 500}}>Referensi</span><br/>
                                                        </React.Fragment>
                                                    )
                                                })()}
                                                <span className={`text-small-grey-nopadding`}>{item?.caption}</span>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </Col>

                    <Col xs={24} xl={24} style={{marginTop: 50}}>
                        <Row className={`pt-5`}>
                            <Col xs={24} xl={6} className={`col-grey`}>Create Time:</Col>
                            <Col xs={24} xl={18}>{this.props.dataDetailUser?.create_time}</Col>
                        </Row>
                    </Col>
                </Row>

                <Modal
                    open={this.state.showBankAccount}
                    title="Detail Rekening Bank"
                    onCancel={() => this.setState({showBankAccount: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button onClick={this.reCheckAPiBankAccount} loading={this.state.submitting} style={(!this.state.detailBankAccount.verified && this.props.allowVerifyBankAccount) ? {} : {display: 'none'}} key="4" type={"primary"}> Check API Bank</Button>,
                        <Button onClick={this.reScanBankAccount} loading={this.state.submitting} style={(!this.state.detailBankAccount.verified && this.props.allowVerifyBankAccount) ? {} : {display: 'none'}} key="0" type={"primary"}> ReScan OCR </Button>,
                        <Button onClick={this.verifyBankAccount} loading={this.state.submitting} style={(!this.state.detailBankAccount.verified && this.props.allowVerifyBankAccount) ? {} : {display: 'none'}} key="1" type={"primary"}> Verify Rekening </Button>,
                        <Button key="4" onClick={() => this.setState({modalConfirmUpdateDefaultBank: true, submitting: false})} style={(this.state.detailBankAccount.verified && this.state.indexBankAccount !== 0) ? {} : {display: 'none'}} type={"primary"}>Jadikan Akun Bank Utama</Button>,
                        <Button key="2" onClick={() => this.setState({showBankAccount: false})}>Close</Button>,
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <span style={{fontWeight: 500}}>{this.state.detailBankAccount.bank_name}</span> - <span>{this.state.detailBankAccount.bank_organization_name}</span>
                        <span style={this.state.detailBankAccount.verified ? {} : {display: 'none'}}><Tooltip title="Verified Bank Account"> &nbsp; <CheckCircleTwoTone twoToneColor="#52c41a"/></Tooltip></span><br/>
                        <span style={{color: "gray"}}>Nomor Rekening: <b style={{paddingLeft: 10}}>{this.state.detailBankAccount?.account_number}</b></span><br/>
                        <span style={{color: "gray"}}>Nama Rekening: <b style={{paddingLeft: 19}}>{this.state.detailBankAccount?.account_name?.toUpperCase()}</b></span><br/>
                        <span style={{color: "gray"}}>Nama KTP: <b style={{paddingLeft: 53}}>{this.props.dataDetailUser?.id_card?.full_name?.toUpperCase()}</b></span><br/>
                        <Divider/>

                        <Row className={`pt-5`}>
                            <Col span={5}>
                                <span className={`text-small-grey-nopadding`}>Image: </span><br/>
                                <Image style={{width: "100%", paddingTop: 5}} alt="image not found" fallback={`https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/vip-amarta.png`} src={this.state.detailBankAccount?.url_image}/>
                            </Col>
                            <Col span={1}/>
                            <Col span={18}>
                                <div style={{fontSize: "80%"}}>
                                    <span className={`text-small-grey-nopadding`}>Bank Verified Result: </span>
                                    <br/>
                                    <span className={`text-small-grey-nopadding`}>Nama Rekening: </span>{this.state.detailBankAccount?.verified_bank_account_name}<br/>
                                </div>
                                <Divider style={{marginTop: 5, marginBottom: 5}}/>
                                <div style={{fontSize: "80%"}}>
                                    <span className={`text-small-grey-nopadding`}>Ocr Result: </span>
                                    <br/>
                                    <div style={{width: "100%", fontSize: "90%"}}>
                                        <span className={`text-small-grey-nopadding`}>Nomor Rekening: </span>{this.state.detailBankAccount?.ocr_result?.result?.bank_account_number}<br/>
                                        <span className={`text-small-grey-nopadding`}>Nama Rekening: </span>{this.state.detailBankAccount?.ocr_result?.result?.bank_account_name}<br/>
                                        <span className={`text-small-grey-nopadding`}>Text OCR: </span> <br/>
                                        {JSON.stringify(this.state.detailBankAccount?.ocr_result?.payload, undefined, 3)}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Divider/>
                    </React.Fragment>
                </Modal>

                <Modal
                    open={this.state.showIdCardOcrData}
                    title="Detail Result OCR ID CARD"
                    onCancel={() => this.setState({showIdCardOcrData: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button onClick={this.reScanIdCard} loading={this.state.submitting} style={(!this.props.dataDetailUser?.id_card_verified && this.props.allowVerifyBankAccount) ? {} : {display: 'none'}} key="0" type={"primary"}> ReScan OCR </Button>,
                        <Button key="2" onClick={() => this.setState({showIdCardOcrData: false})}>Close</Button>,
                    ]}
                >
                    <>
                        <Row className={`pt-5`}>
                            <Col span={6}>
                                <Image style={{width: "100%", paddingTop: 5}} alt="image not found" fallback={`https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/vip-amarta.png`} src={this.props.dataDetailUser?.id_card?.id_card_image}/>
                            </Col>
                            <Col span={1}/>
                            <Col span={17}>
                                <br/>
                                <div style={{width: "100%", fontSize: "90%"}}>
                                    <span className={`text-small-grey-nopadding`}>Nomor KTP: </span><br/>{this.props.dataDetailUser?.id_card?.id_card_number}<br/>
                                    <span className={`text-small-grey-nopadding`}>nama KTP: </span><br/>{this.props.dataDetailUser?.id_card?.full_name}<br/>
                                    <span className={`text-small-grey-nopadding`}>OCR Result: </span><br/>{JSON.stringify(this.props.dataDetailUser?.id_card_ocr, undefined, 3)}
                                </div>
                            </Col>
                        </Row>
                        <Divider/>
                    </>
                </Modal>

                <Modal
                    open={this.state.modalAppboadringAffiliate}
                    title="Detail OnBoard Affiliate Contact"
                    onCancel={() => this.setState({modalAppboadringAffiliate: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button key="2" onClick={() => this.setState({modalAppboadringAffiliate: false})}>Close</Button>
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <DetailAppboardingAffiliate uid={this.props.dataDetailUser?.uid} agentCode={this.props.dataDetailUser?.agent_code} time={Math.floor(+new Date() / 1000)}/>
                        <Divider/>
                    </React.Fragment>
                </Modal>

                <Modal
                    open={this.state.modalUserQr}
                    title="Detail User QR"
                    onCancel={() => this.setState({modalUserQr: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button key="2" onClick={() => this.setState({modalUserQr: false})}>Close</Button>
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <DetailUserQr uid={this.props.dataDetailUser?.uid} time={Math.floor(+new Date() / 1000)}/>
                        <Divider/>
                    </React.Fragment>
                </Modal>

                <Modal
                    open={this.state.modalUserLog}
                    title="Detail User Log"
                    onCancel={() => this.setState({modalUserLog: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button key="2" onClick={() => this.setState({modalUserLog: false})}>Close</Button>
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <DetailUserLog uid={this.props.dataDetailUser?.uid} time={Math.floor(+new Date() / 1000)}/>
                        <Divider/>
                    </React.Fragment>
                </Modal>

                <Modal
                    open={this.state.modalUserDevice}
                    title="Detail Device Log"
                    onCancel={() => this.setState({modalUserDevice: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button key="2" onClick={() => this.setState({modalUserDevice: false})}>Close</Button>
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <DetailUserDevice uid={this.props.dataDetailUser?.uid} time={Math.floor(+new Date() / 1000)}/>
                        <Divider/>
                    </React.Fragment>
                </Modal>

                <Modal
                    open={this.state.modalUserPayroll}
                    title="Detail Payroll Log"
                    onCancel={() => this.setState({modalUserPayroll: false})}
                    cancelText={'Tutup'} okText={false}
                    footer={[
                        <Button key="2" onClick={() => this.setState({modalUserPayroll: false})}>Close</Button>
                    ]}
                >
                    <React.Fragment>
                        <Divider/>
                        <DetailUserPayroll uid={this.props.dataDetailUser?.uid} time={Math.floor(+new Date() / 1000)}/>
                        <Divider/>
                    </React.Fragment>
                </Modal>

                <Modal
                    title="Konfirmasi Rubah Default Bank"
                    open={this.state.modalConfirmUpdateDefaultBank}
                    onOk={this.handleOkDefaultAccount}
                    confirmLoading={this.state.submitting}
                    onCancel={() => this.setState({modalConfirmUpdateDefaultBank: false})}
                >
                    <p>Anda yakin akan menjadikan default akun bank ?</p> <br/>
                </Modal>
            </React.Fragment>
        );
    }
}

export default DetailUser;