import axios from "axios";
import {baseUrlAmartaVip, xApiKeyAmartaVip} from "../../config/apiConfig/apiConfig";

class GroupServices {
    private axios = axios.create({
        baseURL: baseUrlAmartaVip,
    });

    public setToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    public async updateGroup(data?: any) {
        try {
            return await this.axios.put<any>('/user/internal',
                data, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyAmartaVip
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getGroup(params: { type: string }) {
        const queries = {
            ...(params?.type && {
                type: params.type,
            })
        }
        try {
            return await this.axios.get<any>(`/group`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                    "App-Build-Code": "dev",
                    "App-Code": "adminvip"
                },
                params: {...queries,}
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }
}

export const groupServices = new GroupServices();
