import React, {Component} from 'react';
import {Buffer} from "buffer";
import "./AppLayout.css";
import {Avatar, Avatar as UserAvatar, Breadcrumb, Dropdown, Layout, Menu, MenuProps, Typography} from "antd";
import {SettingOutlined, MenuUnfoldOutlined, AimOutlined, TableOutlined, MenuFoldOutlined, UserOutlined, HomeOutlined, VideoCameraOutlined, SolutionOutlined, TeamOutlined, ClusterOutlined} from '@ant-design/icons';
import ButtonLogout from "./LogoutButton";
import SubMenu from "antd/es/menu/SubMenu";
import {Link} from "react-router-dom";
import Cookies from "universal-cookie";
import {appVersion} from '../../config/appDataConfig/appConfig';

const {Header, Sider, Content, Footer} = Layout;
const items: MenuProps['items'] = [
    {
        key: '1',
        label: (<ButtonLogout/>),
    }
];

class AppLayout extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            collapsed: false,
            viewClass: 'desktop',
            user: "",
            area: [],
            defaultArea: {},
            showOptionArea: false,
            listMenu: [],
        }
        this.state = {...this.initState}
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    };

    onChange = (value: any, option: any) => {
        const areaNow = {
            AreaCode: value,
            AreaName: option.children
        }
        this.setState({
            defaultArea: areaNow
        });
        const cookies = new Cookies();
        cookies.set('_vip-da', Buffer.from(JSON.stringify(areaNow)).toString('base64'));

        this.props.onClickClass(option);
    };

    updateDimensions = () => {
        if (window.innerWidth < 1000) {
            this.setState({
                collapsed: true,
                viewClass: 'mobile',
            });
        } else {
            this.setState({
                collapsed: false,
                viewClass: 'desktop',
            });
        }
    };

    async componentDidMount() {
        let user, area, defaultArea, accessMenu;
        const cookies = new Cookies();
        const token = cookies.get('_vip-t');
        if (token) {
            user = JSON.parse(Buffer.from(cookies.get('_vip-u'), "base64").toString());
            area = JSON.parse(Buffer.from(cookies.get('_vip-a'), "base64").toString());
            defaultArea = JSON.parse(Buffer.from(cookies.get('_vip-da'), "base64").toString());
            accessMenu = JSON.parse(Buffer.from(cookies.get('_vip-am'), "base64").toString());
        }

        await this.setState({
            user: user,
            area: area,
            defaultArea: defaultArea,
            showOptionArea: true,
            listMenu: accessMenu,
        });

        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        return (
            <React.Fragment>
                <Layout>
                    <Sider className={`sider`} trigger={null} collapsible collapsed={this.state.collapsed}>
                        <div className="app-main-header"><Avatar src={"/assets/img/favicon.png"}/> <b style={this.state.collapsed ? {display: 'none'} : {fontSize: "115%"}}> AmartaVip <i style={{fontWeight: "normal", fontSize: "50%"}}> v-{appVersion}</i></b></div>
                        <Menu
                            defaultSelectedKeys={this.props.defaultSelectedKeys}
                            defaultOpenKeys={this.props.defaultOpenKeys}
                            mode="inline"
                            theme="dark"
                        >
                            <Menu.Item key="m-dashboard" icon={<HomeOutlined/>}><Link to={`/`}>Dashboard</Link></Menu.Item>
                            {(this.state.listMenu?.includes(30) || this.state.listMenu?.includes(8) || this.state.listMenu?.includes(9) || this.state.listMenu?.includes(26) || this.state.listMenu?.includes(28) || this.state.listMenu?.includes(10) || this.state.listMenu?.includes(14) || this.state.listMenu?.includes(16) || this.state.listMenu?.includes(19)) &&
                                <SubMenu key="sm-uservip" icon={<UserOutlined/>} title="User VIP">
                                    {this.state.listMenu?.includes(8) && <Menu.Item key="m-detailuservip"><Link to={`/user-detail`}>User Detail</Link></Menu.Item>}
                                    {this.state.listMenu?.includes(14) && <Menu.Item key="m-internalUser"><Link to={`/user-internal`}>List Internal / Affiliate</Link></Menu.Item>}

                                    {(this.state.listMenu?.includes(9) || this.state.listMenu?.includes(16)) &&
                                        <SubMenu key="sm-management-uservip" title="User Manajemen">
                                            {this.state.listMenu?.includes(9) && <Menu.Item key="m-updateuservip"><Link to={`/user-update`}>User Update</Link></Menu.Item>}
                                            {this.state.listMenu?.includes(16) && <Menu.Item key="m-activateAffiliate"><Link to={`/activate-affiliate`}>Aktifasi Affiliate</Link></Menu.Item>}
                                        </SubMenu>
                                    }

                                    {(this.state.listMenu?.includes(19) || this.state.listMenu?.includes(30) || this.state.listMenu?.includes(28) || this.state.listMenu?.includes(26)) &&
                                        <SubMenu key="sm-data-uservip" title="User Data">
                                            {this.state.listMenu?.includes(19) && <Menu.Item key="m-orderuservip"><Link to={`/user-order`}>User Order</Link></Menu.Item>}
                                            {this.state.listMenu?.includes(30) && <Menu.Item key="m-ledgeruservip"><Link to={`/user-ledger`}>User Ledger</Link></Menu.Item>}
                                            {this.state.listMenu?.includes(28) && <Menu.Item key="m-tracksosmed"><Link to={`/user-track-sosmed`}>User Track Sosmed</Link></Menu.Item>}
                                            {this.state.listMenu?.includes(26) && <Menu.Item key="m-qruservip"><Link to={`/user-qr`}>User QR</Link></Menu.Item>}
                                        </SubMenu>
                                    }

                                    {(this.state.listMenu?.includes(10)) &&
                                        <SubMenu key="sm-absent-uservip" title="User Absen">
                                            {this.state.listMenu?.includes(10) && <Menu.Item key="m-faceuservip"><Link to={`/user-face-recognition`}>Face & Finger Recognition</Link></Menu.Item>}
                                        </SubMenu>
                                    }


                                </SubMenu>
                            }
                            {(this.state.listMenu?.includes(24) || this.state.listMenu?.includes(25)) &&
                                <SubMenu key="sm-groupvip" icon={<TeamOutlined/>} title="Group VIP">
                                    {this.state.listMenu?.includes(24) && <Menu.Item key="m-groupsupervisor"><Link to={`/group-supervisor`}>Supervisor</Link></Menu.Item>}
                                    {this.state.listMenu?.includes(25) && <Menu.Item key="m-groupteam"><Link to={`/group-team`}>Team</Link></Menu.Item>}
                                </SubMenu>
                            }
                            {(this.state.listMenu?.includes(11) || this.state.listMenu?.includes(13) || this.state.listMenu?.includes(27)) &&
                                <SubMenu key="sm-lead" icon={<ClusterOutlined/>} title="Leads">
                                    {this.state.listMenu?.includes(11) && <Menu.Item key="m-leaduservip"><Link to={`/agent-leads`}>Leads Agent</Link></Menu.Item>}
                                    {this.state.listMenu?.includes(27) && <Menu.Item key="m-leadresumevip"><Link to={`/resume-leads`}>Leads Resume</Link></Menu.Item>}
                                    {this.state.listMenu?.includes(13) && <Menu.Item key="m-leadnotes"><Link to={`/notes-leads`}>Leads Notes</Link></Menu.Item>}
                                </SubMenu>
                            }
                            {(this.state.listMenu?.includes(15) || this.state.listMenu?.includes(18) || this.state.listMenu?.includes(20)) &&
                                <SubMenu key="sm-track" icon={<AimOutlined/>} title="Track Agent">
                                    {this.state.listMenu?.includes(15) && <Menu.Item key="m-leadtrack"><Link to={`/track-leads`}>Leads Track</Link></Menu.Item>}
                                    {this.state.listMenu?.includes(18) && <Menu.Item key="m-testdrivetrack"><Link to={`/track-testdrive`}>TestDrive Track</Link></Menu.Item>}
                                    {this.state.listMenu?.includes(20) && <Menu.Item key="m-ordertrack"><Link to={`/track-order`}>Order Track</Link></Menu.Item>}
                                </SubMenu>
                            }
                            {(this.state.listMenu?.includes(3) || this.state.listMenu?.includes(4)) &&
                                <SubMenu key="sm-talent" icon={<SolutionOutlined/>} title="Trimitra Talent">
                                    {this.state.listMenu?.includes(3) && <Menu.Item key="m-applicant"><Link to={`/list-applicant`}>List Applicant</Link></Menu.Item>}
                                    {this.state.listMenu?.includes(4) && <Menu.Item key="m-interview"><Link to={`/list-interview`}>Jadwal Interview</Link></Menu.Item>}
                                </SubMenu>
                            }
                            {(this.state.listMenu?.includes(5) || this.state.listMenu?.includes(6) || this.state.listMenu?.includes(7) || this.state.listMenu?.includes(12)) &&
                                <SubMenu key="sm-activity" icon={<VideoCameraOutlined/>} title="Activity">
                                    {(this.state.listMenu?.includes(5) || this.state.listMenu?.includes(6) || this.state.listMenu?.includes(7)) &&
                                        <SubMenu key="sm-form-activity" title="Approval Activity">
                                            {this.state.listMenu?.includes(5) && <Menu.Item key="m-form-app"><Link to={`/activity-application-form`}>Application Form</Link></Menu.Item>}
                                            {this.state.listMenu?.includes(6) && <Menu.Item key="m-list-app"><Link to={`/list-activity-application`}>Application List</Link></Menu.Item>}
                                            {this.state.listMenu?.includes(7) && <Menu.Item key="m-activity-result"><Link to={`/list-activity-result`}>Activity Result</Link></Menu.Item>}
                                        </SubMenu>
                                    }
                                    {(this.state.listMenu?.includes(12)) &&
                                        <SubMenu key="sm-testdrive-activity" title="Test Drive Activity">
                                            {this.state.listMenu?.includes(12) && <Menu.Item key="m-test-drive"><Link to={`/list-test-drive`}>Test Drive</Link></Menu.Item>}
                                        </SubMenu>
                                    }
                                    {(this.state.listMenu?.includes(23)) &&
                                        <SubMenu key="sm-downloadlog-activity" title="Download Log Activity">
                                            {this.state.listMenu?.includes(23) && <Menu.Item key="m-download-log"><Link to={`/download-log`}>Download Log</Link></Menu.Item>}
                                        </SubMenu>
                                    }
                                </SubMenu>
                            }

                            {(this.state.listMenu?.includes(21) || this.state.listMenu?.includes(22) || this.state.listMenu?.includes(29)) &&
                                <SubMenu key="sm-other" icon={<TableOutlined/>} title="Other">
                                    {(this.state.listMenu?.includes(21) || this.state.listMenu?.includes(22)) &&
                                        <SubMenu key="sm-worksheet" title="Worksheet">
                                            {this.state.listMenu?.includes(21) && (<Menu.Item key="m-import-worksheet"><Link to={`/worksheet/import`}>Import Worksheet</Link></Menu.Item>)}
                                            {this.state.listMenu?.includes(22) && (<Menu.Item key="m-list-worksheet"><Link to={`/worksheet`}>List Worksheet</Link></Menu.Item>)}
                                        </SubMenu>
                                    }
                                    {(this.state.listMenu?.includes(29)) &&
                                        <SubMenu key="sm-blacklist" title="Blacklist">
                                            {this.state.listMenu?.includes(29) && (<Menu.Item key="m-blacklist"><Link to={`/blacklist`}>Blacklist</Link></Menu.Item>)}
                                        </SubMenu>
                                    }
                                </SubMenu>
                            }

                            {(this.state.listMenu?.includes(2) || this.state.listMenu?.includes(17)) &&
                                <SubMenu key="sm-setting" icon={<SettingOutlined/>} title="Setting">
                                    {this.state.listMenu?.includes(2) && <Menu.Item key="m-talent"><Link to={`/master-talent`}>Master Talent</Link></Menu.Item>}
                                    {this.state.listMenu?.includes(17) && <Menu.Item key="m-track-agent"><Link to={`/master-track-agent`}>Master Tracking Agent</Link></Menu.Item>}
                                </SubMenu>
                            }
                        </Menu>
                    </Sider>
                    <Layout className="site-layout">
                        <Header className="site-layout-background">
                            {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                className: 'trigger',
                                onClick: this.toggle,
                            })}

                            <Dropdown className={'header-right'} menu={{items}}>
                            <span className="action account">
                                <UserAvatar size="small" icon={<UserOutlined/>} className="avatar"/>
                                <Typography.Text style={this.state.collapsed ? {display: 'none'} : {}}> &nbsp; {this.state.user}</Typography.Text>
                            </span>
                            </Dropdown>
                        </Header>
                        <Breadcrumb className={'breadcrumb-padding'}>
                            <Breadcrumb.Item>{this.props.breadcrumbPath}</Breadcrumb.Item>
                            <Breadcrumb.Item>{this.props.breadcrumbMenu}</Breadcrumb.Item>
                        </Breadcrumb>
                        <Content className="site-layout-background" style={this.props.noPadNoMar ? {margin: '0px 16px -10px', padding: 0, minHeight: '85vh'} : {margin: '0px 16px -10px', padding: 24, minHeight: '85vh'}}>
                            <div>{this.props.children}</div>
                        </Content>
                        <Footer className={'footer-admin'}>AmartaVip ©{new Date().getFullYear()} v-{appVersion} </Footer>
                    </Layout>
                </Layout>
            </React.Fragment>
        );
    }
}

export default AppLayout;