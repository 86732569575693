import React from "react";
import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./redux";
import Login from "./screen/auth/Login";
import Home from "./screen/home/Home";
import MasterTalent from "./screen/talent/MasterTalent";
import MasterTrackAgent from "./screen/master/MasterTrackAgent";
import ListApplicant from "./screen/talent/ListApplicant";
import ListInterview from "./screen/talent/ListInterview";
import FormVenueActivityApplication from "./screen/formApprovalActivity/FormVenueActivityApplication";
import ListApprovalActivity from "./screen/formApprovalActivity/ListApprovalActivity";
import ListResultActivity from "./screen/formApprovalActivity/ListResultActivity";
import UserDetail from "./screen/user/UserDetail";
import UserOrder from "./screen/user/UserOrder";
import UserLedger from "./screen/user/UserLedger";
import UserTrackMedsos from "./screen/user/UserTrackMedsos";
import UserQr from "./screen/user/UserQr";
import UpdateUser from "./screen/user/UpdateUser";
import UserFaceRecognition from "./screen/user/UserFaceRecognition";
import UserLead from "./screen/user/UserLead";
import ResumeLead from "./screen/user/ResumeLead";
import ListTestDriveActivity from "./screen/testDrive/ListTestDriveActivity";
import NotesLead from "./screen/user/NotesLead";
import TrackLead from "./screen/user/TrackLead";
import TrackTestDrive from "./screen/track/TrackTestDrive";
import DonwloadLogActivity from "./screen/downloadLog/DonwloadLogActivity";
import TrackOrder from "./screen/track/TrackOrder";
import InternalUser from "./screen/user/InternalUser";
import ActivateAffiliate from "./screen/user/ActivateAffiliate";
import Test from "./screen/test/Test";
import NotFound from "./screen/home/NotFound";
import Supervisor from "./screen/group/Supervisor";
import Team from "./screen/group/Team";
import Blacklist from "./screen/blacklist/Blacklist";

import ImportWorksheet from "./screen/worksheet/ImportWorksheet";
import ListWorksheet from "./screen/worksheet/ListWorksheet";

function App() {
    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route path="/login" caseSensitive={false} element={<Login/>}/>
                    <Route path="/test" caseSensitive={false} element={<Test/>}/>
                    <Route path="/user-detail" caseSensitive={false} element={<UserDetail/>}/>
                    <Route path="/user-update" caseSensitive={false} element={<UpdateUser/>}/>
                    <Route path="/user-order" caseSensitive={false} element={<UserOrder/>}/>
                    <Route path="/user-ledger" caseSensitive={false} element={<UserLedger/>}/>
                    <Route path="/user-track-sosmed" caseSensitive={false} element={<UserTrackMedsos/>}/>
                    <Route path="/user-qr" caseSensitive={false} element={<UserQr/>}/>
                    <Route path="/user-internal" caseSensitive={false} element={<InternalUser/>}/>
                    <Route path="/activate-affiliate" caseSensitive={false} element={<ActivateAffiliate/>}/>
                    <Route path="/user-face-recognition" caseSensitive={false} element={<UserFaceRecognition/>}/>
                    <Route path="/agent-leads" caseSensitive={false} element={<UserLead/>}/>
                    <Route path="/resume-leads" caseSensitive={false} element={<ResumeLead/>}/>
                    <Route path="/notes-leads" caseSensitive={false} element={<NotesLead/>}/>
                    <Route path="/track-leads" caseSensitive={false} element={<TrackLead/>}/>
                    <Route path="/track-testdrive" caseSensitive={false} element={<TrackTestDrive/>}/>
                    <Route path="/track-order" caseSensitive={false} element={<TrackOrder/>}/>
                    <Route path="/master-talent" caseSensitive={false} element={<MasterTalent/>}/>
                    <Route path="/list-applicant" caseSensitive={false} element={<ListApplicant/>}/>
                    <Route path="/list-interview" caseSensitive={false} element={<ListInterview/>}/>
                    <Route path="/activity-application-form" caseSensitive={false} element={<FormVenueActivityApplication/>}/>
                    <Route path="/list-activity-application" caseSensitive={false} element={<ListApprovalActivity/>}/>
                    <Route path="/list-activity-result" caseSensitive={false} element={<ListResultActivity/>}/>
                    <Route path="/list-test-drive" caseSensitive={false} element={<ListTestDriveActivity/>}/>
                    <Route path="/master-track-agent" caseSensitive={false} element={<MasterTrackAgent/>}/>
                    <Route path="/worksheet/import" caseSensitive={false} element={<ImportWorksheet/>}/>
                    <Route path="/worksheet" caseSensitive={false} element={<ListWorksheet/>}/>
                    <Route path="/download-log" caseSensitive={false} element={<DonwloadLogActivity/>}/>
                    <Route path="/group-supervisor" caseSensitive={false} element={<Supervisor/>}/>
                    <Route path="/group-team" caseSensitive={false} element={<Team/>}/>
                    <Route path="/blacklist" caseSensitive={false} element={<Blacklist/>}/>
                    <Route path="/" caseSensitive={false} element={<Home/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </Router>
        </Provider>
    );
}

export default App;