const production = true;

/* CHERY */
const appData = {
    appVersion: "1.5.2",
    appProduction: production,
    appCompanyCode: "amartaVip",
    appCompanyName: "amartaVip",
    appCode: "amartaVip",
    appAbbreviation: "VIP",
}

export const appProduction = appData?.appProduction;
export const appCompanyCode = appData?.appCompanyCode;
export const appCompanyName = appData?.appCompanyName;
export const appCode = appData?.appCode;
export const appAbbreviation = appData?.appAbbreviation;
export const appVersion = appData?.appVersion;