export const allowedOrganization = [
    {
        companyCode: "AMARTAHONDA",
        companyId: "AMARTA",
        amartaVipCode: "AMARTAHONDA",
        otodisCode: "AMARTA",
        offerCode: "AMARTA",
        copanCode: "AMARTA",
        copanAreaCode: "JABAR2",
        copanDefaultBrand: "HONDA",
    },
    {
        companyCode: "AMARTACHERY",
        companyId: "CHERY",
        amartaVipCode: "CHERY",
        otodisCode: "CHERY",
        offerCode: "CHERY",
        copanCode: "CHERY",
        copanAreaCode: "JAWABARAT",
        copanDefaultBrand: "CHERY",
    },
    {
        companyCode: "AMARTANETA",
        companyId: "NETA",
        amartaVipCode: "NETA",
        otodisCode: "NETA",
        offerCode: "NETA",
        copanCode: "NETA",
        copanAreaCode: "JAWABARAT",
        copanDefaultBrand: "NETA",
    },
    {
        companyCode: "TRIMOBI",
        companyId: "TRIMOBI",
        amartaVipCode: "TRIMOBI",
        otodisCode: "TRIMOBI",
        offerCode: "TRIMOBI",
        copanCode: "TRIMOBI",
        copanAreaCode: "JAWABARAT",
        copanDefaultBrand: "TRIMOBI",
    },
    {
        companyCode: "AMARTAVINFAST",
        companyId: "VINFAST",
        amartaVipCode: "VINFAST",
        otodisCode: "VINFAST",
        offerCode: "VINFAST",
        copanCode: "VINFAST",
        copanAreaCode: "JAWABARAT",
        copanDefaultBrand: "VINFAST",
    }
];

export const optSMSResult = [
    "PENDING", // (Menunggu)
    "WRONG NUMBER", // (Salah Nomor)
    "NOT DELIVERED", // (Tidak Terkirim)
    "DELIVERED", // (Diterima)
    "READ", // (Dibaca)
    "BLOCKED", // (Diblokir)
    "INTERESTED", // (Tertarik)
    "NOT INTERESTED", // (Tidak Tertarik)
];

export const optCallResult = [
    "ANSWERED", // (Dijawab)
    "NOT ANSWERED", // (Tidak Dijawab)
    "BUSY", // (Sibuk)
    "WRONG NUMBER", // (Salah Nomor)
    "LEFT VOICEMAIL", // (Meninggalkan Pesan Suara)
    "INTERESTED", // (Tertarik)
    "NOT INTERESTED", // (Tidak Tertarik)
    "CALLBACK SCHEDULED", // (Jadwal Panggilan Kembali)
    "REACHED DECISION MAKER", // (Berhasil Menghubungi Pengambil Keputusan)
    "BLOCKED", // (Diblokir)
];

export const optVisitResult = [
    "SCHEDULED", // (Jadwal Pertemuan)
    "VISITED", // (Dikunjungi)
    "NOT HOME", // (Tidak Ada di Rumah)
    "INTERESTED", // (Tertarik)
    "NOT INTERESTED", // (Tidak Tertarik)
    "POSTPONED", // (Ditunda)
    "RESCHEDULED", // (Dijadwalkan Ulang)
];
